import { Dropdown } from "react-bootstrap";
import classes from "./../../style.module.scss";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { ButtonDownloadBulkTbTablesCsv } from "./buttonDownloadBulkTbTableCsv";


interface Props {
    nodeDisplay: boolean,
    nodeResult: boolean,
    checkAypaToken: Function

}

export const BulkButtonDownloadMenu = ({nodeDisplay, nodeResult, checkAypaToken}: Props) => {
    checkAypaToken();
    return (
       
            <Dropdown className={classes.card__dropdown}>
                <Dropdown.Toggle id="dropdown-downloads">
                    Download
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {nodeResult && <Dropdown.Item as={ButtonDownloadBulkTbTablesCsv}></Dropdown.Item>}
                </Dropdown.Menu>
            </Dropdown>
    )
}
