import { Table } from "reactstrap";
import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import classes from "./../../style.module.scss";
import { formatCurrency } from "common/utils/utils";
import { months } from "common/components/lineChart/values";
import { NodeResults, SumTopBottomData } from "common/interfaces/topBottomData";
import React from "react";
import { BulkTbRowData, convertTBSumToRows } from "../../functions/topBottomFunctions";


interface tableProps {
    group: string
}
export const TopBottomTable = ({ group }: tableProps) => {
    const topBottomSummaryData: {[key: string]: SumTopBottomData} = useSelector((store: ApplicationState) => store.bulkReducer.topBottomSummaryData);
    const bulkPriceList: Array<NodeResults> = useSelector((store: ApplicationState) => store.bulkReducer.bulkPriceList);
    const [tableHeader, setTableHeader] = useState<JSX.Element | null>(null);
    const [tableBody, setTableBody] = useState<JSX.Element[] | null>(null);

    const onCompleteFetchTopBottomtData = (dataObjects: {[key: string]: SumTopBottomData}) => {
        const rowData: {data: BulkTbRowData, monthRange: Array<number>} = convertTBSumToRows(dataObjects, bulkPriceList, group);
        switch (group) {
            case "total":
                genTableTotal(rowData.data);
                break;
            case "monthly":
                genTableMonthly(rowData.data, rowData.monthRange);
                break;
        }
    }

    function genTableTotal(data: BulkTbRowData){
        setTableHeader(
            <tr>
                <th>Node Name</th>
                <th>DA</th>
                <th>RT</th>
            </tr>
        );
        setTableBody(
            (Object.values(data).map((item, index) => (
                <tr key={index}>
                    <td>{item.name}</td>
                    {item.data.map((item2, index2) => (
                        <React.Fragment key={index + "_" + index2}>
                            <td key={index+"_"+index2+"da"}>{item2.tb_da ? formatCurrency(item2.tb_da) : '-'}</td>
                            <td key={index+"_"+index2+"rt"}>{item2.tb_rt ? formatCurrency(item2.tb_rt) : '-'}</td>
                        </React.Fragment>
                    ))}
                </tr>
            )))
        );
    }
    function genTableMonthly(data: BulkTbRowData, monthRange: Array<number>) {
        setTableHeader(
            <tr>
                <th>Node Name</th>
                {monthRange.map((month) => (
                    <React.Fragment key={month}>
                    <th key={month+"da"}>DA {months[month - 1]}</th>
                    <th key={month+"rt"}>RT {months[month - 1]}</th>
                    </React.Fragment>
                ))}
            </tr>
        );
        setTableBody(
            (Object.values(data).map((item, index) => (
                <tr key={index+"gg"}>
                    <td>{item.name}</td>
                    {monthRange.map(month => {
                        const monthData = item.data.find(y => y.month === month);
                        return <React.Fragment key={month}>
                            <td key={month+'da'}>{monthData && monthData.tb_da ? formatCurrency(monthData.tb_da) : '-'}</td>
                            <td key={month+'rt'}>{monthData && monthData.tb_rt ? formatCurrency(monthData.tb_rt) : '-'}</td>
                            </React.Fragment>;
                    })}
                </tr>
            )))
        );
    }

    useEffect(() => {
        if (Object.keys(topBottomSummaryData).length !== 0) {
            onCompleteFetchTopBottomtData(topBottomSummaryData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topBottomSummaryData])

    return (
        <Table striped hover responsive className={classes.topBottomTable}>
            <thead>
                {tableHeader}
            </thead>
            <tbody>
                {tableBody}
            </tbody>
        </Table>
    )
}