import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import sprites from "common/assets/img/sprites.svg";
import classes from "./../../style.module.scss";
import { createMetadataTbBulk, DataToFileDownload, formatCurrency} from "common/utils/utils";
import { months } from "common/components/lineChart/values";
import { NodeResults, SumTopBottomData } from "common/interfaces/topBottomData";
import { BulkTbRowData, convertTBSumToRows } from "../../functions/topBottomFunctions";
import { BulkState } from "store/states/bulk-state";

export const ButtonDownloadBulkTbTablesCsv = () => {
    /**SELECTORS */
    const topBottomSummaryData: {[key: string]: SumTopBottomData} = useSelector((store: ApplicationState) => store.bulkReducer.topBottomSummaryData);
    const bulkPriceList: Array<NodeResults> = useSelector((store: ApplicationState) => store.bulkReducer.bulkPriceList);
    const bulkReducer: BulkState = useSelector((store: ApplicationState) => store.bulkReducer);

    const sourceName = useSelector((store: ApplicationState) => store.settingsReducer.sourceName);

    /**CONSTANTS */
    let titleButton = 'Download TB Table';


    const downloadTbCSV = () => {
        // Convert data array to CSV
        const rowDataTotal: {data: BulkTbRowData, monthRange: Array<number>} = convertTBSumToRows(topBottomSummaryData, bulkPriceList, 'total');
        const rowDataMonthly: {data: BulkTbRowData, monthRange: Array<number>} = convertTBSumToRows(topBottomSummaryData, bulkPriceList, 'monthly');
        let csvRows: Array<string> = [];
        csvRows = convertToRows(rowDataTotal.data, "total", []);
        csvRows = [...csvRows, ...convertToRows(rowDataMonthly.data, "monthly", rowDataMonthly.monthRange)];
        let  csvContent = createMetadataTbBulk(bulkReducer, sourceName) + csvRows.join('\n');
        let filename = "TB_Bulk";
        DataToFileDownload(csvContent, filename);
      };

    const convertToRows = (data: BulkTbRowData, group: string, monthRange: Array<number>) => {
        const rows: Array<string> = [];
        switch (group) {
            case "total":
                rows.push("Total");
                rows.push(...processRows(data, false, monthRange));
                rows.push("");
                break;
            case "monthly":
                rows.push("Monthly");
                rows.push(...processRows(data, true, monthRange));
                rows.push("");
                break;
        }
        return rows;
    }

    const processRows = (data: BulkTbRowData, month: boolean, monthRange: Array<number>) => {
        const rows: Array<string> = [];
        let header: Array<string> = [];
        if (month) {
            header.push("Node Name");
            monthRange.forEach(x => {
                header.push("DA " + months[x -1]);
                header.push("RT " + months[x -1]);
            });
        } else {
            header = ["Node Name", "DA", "RT"];
        }
        rows.push(header.join(","));
        for (const key in data) {
            let bodyRow = [];
            const value = data[key];
            bodyRow.push(value.name);
            if (month) {
                monthRange.forEach(month => {
                    const monthData = value.data.find(y => y.month === month);
                    bodyRow.push(monthData && monthData.tb_da ? formatCurrency(monthData.tb_da) : '');
                    bodyRow.push(monthData && monthData.tb_rt ? formatCurrency(monthData.tb_rt) : '');
                });
            } else {
                // total only has 1 value
                bodyRow.push(value.data[0].tb_da ? formatCurrency(value.data[0].tb_da) : '');
                bodyRow.push(value.data[0].tb_rt ? formatCurrency(value.data[0].tb_rt) : '');
            }
            rows.push(bodyRow.join(","))
        }
        return rows;
    }

    const handleDownload = () => {
        if (Object.keys(topBottomSummaryData).length > 0) {
            downloadTbCSV();
        } else {
          alert('No data available to download');
        }
      };

    return (
        <div className={classes.card__dropdownitem}>
            <button onClick={handleDownload}>
                <span>
                    <svg>
                        <use xlinkHref={`${sprites}#icon-export`}></use>
                    </svg>
                </span>
                {titleButton}
            </button>
        </div>
    )
}
