
import { NodeResults, SumTopBottomData, TopBottomRevenueData } from "common/interfaces/topBottomData";

export interface BulkTbRowData {
    [key: string]: {name: string, data: Array<TopBottomRevenueData>}
}

/***
 * Converts the dataObjects into row values.  Each Node in bulkPriceList could have multiple streams associated "DA" or "RT".
 * It groups by Node, gets the streams associated, gets the node name, and returns the bulkTbRowData.
 * 
 * dataObjects: topBottomSummaryData from bulk-slice
 * bulkPriceList: bulkPriceList from bulk-slice
 * group: tab group - 'total' or 'monthly'
 * returns: data = TopBottomRevenueData use da, rt values for Historical. It is the kw per month value.  Not all values will have both da and rt.
 *      monthRange: The min/max months assoicated with the request.  Not all streams could have the date range.
 */
export function convertTBSumToRows(dataObjects: {[key: string]: SumTopBottomData},
        bulkPriceList: Array<NodeResults>, group: string): {data: BulkTbRowData, monthRange: Array<number>} {
    const dataObj: BulkTbRowData = {};
    // find min and max dates
    let minYear = null;
    let maxYear = null;
    let minMonth = null;
    let maxMonth = null;
    // obj for all streams and nodes.  need to group by node for streams
    for (const key in dataObjects) {
        // find matching node in bulkPriceList
        let matchingPriceList: NodeResults | null = null;
        // day ahead is true, real time is false, null is nodal
        let dayAhead: boolean | null = null;
        for (const price of bulkPriceList) {
            if (price.streams.length > 0) {
                let foundMatch = false;
                for (const stream of price.streams) {
                    if (stream.eventId === key) {
                        matchingPriceList = price;
                        dayAhead = stream.priceType === "DAY-AHEAD";
                        foundMatch = true;
                        break;
                    }
                }
                if (foundMatch) {
                    break;
                }
            } else if (price.eventId === key) {
                matchingPriceList = price;
                break;
            }
        }
        if (matchingPriceList === null) {
            continue;
        }
        const data: SumTopBottomData = dataObjects[key];
        switch (group) {
            case "total":
                if (matchingPriceList.eventId in dataObj) {
                    // only NRG can have DA / RT
                    if (dayAhead) {
                        dataObj[matchingPriceList.eventId].data[0].tb_da = data.total.top_bottom_revenue_per_kwmonth
                    } else {
                        dataObj[matchingPriceList.eventId].data[0].tb_rt = data.total.top_bottom_revenue_per_kwmonth
                    }
                } else {
                    const totalNew: TopBottomRevenueData = { ...data.total, tb_da: null, tb_rt: null };
                    if (dayAhead) {
                        totalNew.tb_da = data.total.top_bottom_revenue_per_kwmonth
                    } else {
                        totalNew.tb_rt = data.total.top_bottom_revenue_per_kwmonth
                    }
                    dataObj[matchingPriceList.eventId] = {name: data.name, data: [totalNew]}
                }
                break;
            case "monthly":
                if ((minMonth === null) || minMonth > data.monthly[0].month) {
                    minMonth = data.monthly[0].month;
                }
                if ((maxMonth === null) || maxMonth < data.monthly[data.monthly.length - 1].month) {
                    maxMonth = data.monthly[data.monthly.length - 1].month;
                }
                if (matchingPriceList.eventId in dataObj) {
                    // only NRG can have DA / RT
                    data.monthly.forEach(x => {
                        // complier thinks matchingPriceList' is possibly 'null'.  it is checked before switch 
                        const monthData = dataObj[matchingPriceList!.eventId].data.find(y => y.month === x.month);
                        if (monthData) {
                            if (dayAhead) {
                                monthData.tb_da = x.top_bottom_revenue_per_kwmonth;
                            } else {
                                monthData.tb_rt = x.top_bottom_revenue_per_kwmonth;
                            }
                        } else {
                            // no matching month - make new ?
                            if (dayAhead) {
                                dataObj[matchingPriceList!.eventId].data.push({ ...x, tb_da: x.top_bottom_revenue_per_kwmonth, tb_rt: null });
                            } else {
                                dataObj[matchingPriceList!.eventId].data.push({ ...x, tb_da: null, tb_rt: x.top_bottom_revenue_per_kwmonth });
                            }
                        }
                    });
                } else {
                    const monthlyNew: Array<TopBottomRevenueData> = [];
                    data.monthly.forEach(x => {
                        monthlyNew.push({ ...x, tb_da: null, tb_rt: null });
                    })
                    if (dayAhead) {
                        monthlyNew.forEach(x => {
                            x.tb_da = x.top_bottom_revenue_per_kwmonth;
                            });
                    } else {
                        monthlyNew.forEach(x => {
                            x.tb_rt = x.top_bottom_revenue_per_kwmonth;
                            });
                    }
                    dataObj[matchingPriceList.eventId] = {name: data.name, data: monthlyNew}
                }
                break;
        }
    }
    // get header values - Total (total), Jan Feb Mar..etc (monthly), 2024, 2023 (yearly)
    // some data might not have all the data.  need full range.  stream1=Jan, Feb, Mar. stream2=May Jun July. full header = Jan - Jul
    let yearRange: Array<number> = [];
    let monthRange: Array<number> = [];
    if (minYear && maxYear) {
        for (let year = minYear; year <= maxYear; year++) {
            yearRange.push(year);
        }
    }
    if (minMonth && maxMonth) {
        for (let month = minMonth; month <= maxMonth; month++) {
            monthRange.push(month);
        }
    }
    return {data: dataObj, monthRange: monthRange}
}
