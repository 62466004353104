import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { Accordion, Card } from "react-bootstrap";

import classes from "./../style.module.scss";
import { CardProps } from "common/interfaces/cardProps";
import { BulkCustomToggle } from "../components/customToggle"
import { CardTitle } from "common/components/accordionCard/cardTitle";
import { BulkNodesTable } from "./bulkNodesTable";
import { BulkButtonDownloadMenu } from "../components/bulkButtonDownload/bulkButtonDownloadMenu";

export const BulkNodesDisplay = ({ eventKey, stack, checkAypaToken }: CardProps) => {

    const activeCard = useSelector((store: ApplicationState) => store.bulkReducer.activeCard);
    const title1 = `Node Search`

    return (
        <Card className={classes.card}>
            <BulkCustomToggle as={Card.Header} eventKey={eventKey} stack={stack}>
                <CardTitle title={title1} eventKey={activeCard} order={eventKey} />
                {activeCard === '0' &&
                    <>
                        {/* <BulkButtonDownloadMenu nodeDisplay={true} nodeResult={false} checkAypaToken={checkAypaToken}></BulkButtonDownloadMenu> */}
                    </>
                }
            </BulkCustomToggle>
            <Accordion.Collapse eventKey={eventKey}>
                <Card.Body className={`${classes.body} scrollable`}>
                     <BulkNodesTable></BulkNodesTable>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}